import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import ActiveNotingLabel from 'components/ActiveNotingLabel';
import AlertIcon from 'svg/alert.svg';
import CheckNegative from 'svg/check-negative.svg';
import * as constants from '../../../constants';
import messages from '../../../messages';
import styles from './Cells.pcss';


class Cell extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    type      : PropTypes.string.isRequired,
    value     : PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    readingsAverage: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    standards: PropTypes.shape({
      maxValue: PropTypes.number,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    kpi      : PropTypes.object,
    // Explicit actions
    onAddNote: PropTypes.func,
  };


  static defaultProps = {
    kpi: {},
  };


  onClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.props.onAddNote) {
      return;
    }
    const { type, value } = this.props;
    const noteType = upperFirst(type);
    const { status, unitSymbol } = this;
    const titleMessage = messages.kpi[type];
    this.props.onAddNote(noteType, { value, unitSymbol, status, titleMessage });
  }


  get status() {
    const { type, value, readingsAverage, standards, kpi, conversion } = this.props;
    let valueHigh;
    let valueLow;
    let allowedMeasurements;

    switch (type) {
      case constants.KPI_TYPES.GMI:
        valueHigh = get(kpi, 'valueHigh', constants.GMI_TARGETS.HIGH);
        valueLow = get(kpi, 'valueLow', constants.GMI_TARGETS.LOW);
        if (value > valueHigh) { return constants.KPI_TARGETS.HIGH; }
        if (value < valueLow) { return constants.KPI_TARGETS.LOW; }
        return constants.KPI_TARGETS.TARGET;

      case constants.KPI_TYPES.CV:
        valueHigh = get(kpi, 'valueHigh', constants.CV_TARGETS.NEUTRAL);
        if (value > valueHigh) { return constants.KPI_TARGETS.HIGH; }
        return constants.KPI_TARGETS.NEUTRAL;

      case constants.KPI_TYPES.STANDARD_DEVIATION:
        valueHigh = get(kpi, 'valueHigh');
        if (valueHigh) {
          valueHigh = conversion.toDisplay(valueHigh);
        } else {
          valueHigh = (parseFloat(readingsAverage) / 3);
        }

        if (value > valueHigh) { return constants.KPI_TARGETS.HIGH; }
        return constants.KPI_TARGETS.NEUTRAL;

      case constants.KPI_TYPES.VERY_HIGH:
        allowedMeasurements = get(kpi, 'allowedMeasurements', 0);
        if (value <= allowedMeasurements) { return constants.KPI_TARGETS.TARGET; }
        return constants.KPI_TARGETS.HIGH;

      case constants.KPI_TYPES.VERY_LOW:
        allowedMeasurements = get(kpi, 'allowedMeasurements', 0);
        if (value <= allowedMeasurements) { return constants.KPI_TARGETS.TARGET; }
        return constants.KPI_TARGETS.LOW;

      case constants.KPI_TYPES.AVERAGE:
      case constants.KPI_TYPES.AVERAGE_POST_MEAL:
        if (standards) {
          const normalizedValue = conversion.toStorage(value, 2);
          if (normalizedValue < standards.postMeal.lowThreshold) { return constants.KPI_TARGETS.LOW; }
          if (normalizedValue < standards.postMeal.highThreshold) { return constants.KPI_TARGETS.TARGET; }
        }
        return constants.KPI_TARGETS.HIGH;

      case constants.KPI_TYPES.AVERAGE_PRE_MEAL:
        if (standards) {
          const normalizedValue = conversion.toStorage(value, 2);
          if (normalizedValue < standards.preMeal.lowThreshold) { return constants.KPI_TARGETS.LOW; }
          if (normalizedValue < standards.preMeal.highThreshold) { return constants.KPI_TARGETS.TARGET; }
        }
        return constants.KPI_TARGETS.HIGH;

      default:
        return constants.KPI_TARGETS.NEUTRAL;
    }
  }


  get borderClass() {
    switch (this.status) {
      case constants.KPI_TARGETS.LOW:
        return styles.kpiCell_low;

      case constants.KPI_TARGETS.HIGH:
        return styles.kpiCell_high;

      case constants.KPI_TARGETS.TARGET:
        return styles.kpiCell_target;

      default:
        return styles.kpiCell_empty;
    }
  }


  get unitSymbol() {
    const { type, conversion } = this.props;
    switch (type) {
      case constants.KPI_TYPES.CV:
      case constants.KPI_TYPES.GMI:
        return '%';
      case constants.KPI_TYPES.STANDARD_DEVIATION:
      case constants.KPI_TYPES.AVERAGE:
      case constants.KPI_TYPES.AVERAGE_PRE_MEAL:
      case constants.KPI_TYPES.AVERAGE_POST_MEAL:
        return ` ${conversion.unitSymbol}`;
      default:
        return '';
    }
  }


  get value() {
    const { value } = this.props;
    const { unitSymbol } = this;
    return `${value}${unitSymbol}`;
  }


  renderIcon() {
    let icon = null;

    switch (this.status) {
      case constants.KPI_TARGETS.LOW:
        icon = <AlertIcon />;
        break;
      case constants.KPI_TARGETS.TARGET:
        icon = <CheckNegative />;
        break;
      case constants.KPI_TARGETS.HIGH:
        icon = <AlertIcon />;
        break;

      default:
        break;
    }
    return icon;
  }


  renderValue() {
    return (
      <div className={styles.kpiCell__value}>
        { this.renderIcon() }
        { this.value }
      </div>
    );
  }


  renderTitle() {
    return (
      <div className={styles.kpiCell__title}>
        <FormattedMessage {...messages.kpi[this.props.type]} />
      </div>
    );
  }


  renderActiveNotingLabel() {
    if (!this.props.onAddNote) {
      return null;
    }
    return <ActiveNotingLabel />;
  }


  render() {
    return (
      <a
        href=""
        className={
          cn(styles.kpiCell,
            this.props.type === constants.KPI_TYPES.GMI ? styles['kpiCell--wider'] : '',
            this.borderClass,
            { activeNoting: this.props.onAddNote })
        }
        onClick={(evt) => this.onClick(evt)}
      >
        { this.renderActiveNotingLabel() }
        { this.renderValue() }
        { this.renderTitle() }
      </a>
    );
  }

}

export default withStyles(styles)(Cell);
