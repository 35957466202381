import { FormattedMessage } from 'react-intl';
import React, { FC, memo, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppContext } from 'context';
import messages from '../../messages';
import styles from './CurrentVersion.pcss';


const CurrentVersion: FC = () => {
  const { currentVersion, releaseDate, changelogUrl } = useContext(AppContext);
  useStyles(styles);


  return (
    <div className={styles.currentVersion}>
      <div className={styles.currentVersion__versionName}>
        <FormattedMessage {...messages.sidebar.labels.currentVersion} />: { currentVersion }
      </div>
      <div className={styles.currentVersion__releaseDate}>
        <FormattedMessage {...messages.sidebar.labels.releaseDate} />: { releaseDate }
      </div>
      <a
        href={changelogUrl}
        target="_blank"
        className={styles.currentVersion__changeLogUrl}
      ><FormattedMessage {...messages.sidebar.labels.seeChangeLog} />
      </a>
    </div>
  );
};

export default memo(CurrentVersion);
